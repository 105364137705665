// Import Library CSS
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Import Global CSS
import "./src/styles/Globals.scss";

import provideStores from "./provide-stores";

export const wrapRootElement = provideStores;
