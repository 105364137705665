import { action, makeObservable, observable } from "mobx";

class SiteSettingStore {
  siteLanguage = "en";

  constructor() {
    makeObservable(this, {
      siteLanguage: observable,
      setSiteLanguage: action,
    });
  }

  setSiteLanguage(lang) {
    this.siteLanguage = lang;
  }
}

export default SiteSettingStore;
